<template>
    <div class="lg:col-span-2 mt-10 lg:mt-20 flex flex-col gap-y-6">
        <div class="flex flex-col gap-y-2">
            <label class="text-body-4 block text-label" for="userCity">
                Település*
            </label>
            <input class="input-with-border w-full" id="userCity" type="text" placeholder="pl.: Budapest" v-model="userData.userCity" name="userCity"
                   :class="{ '!border-statusError' : errors.userCity && fieldTouched.userCity }" @blur="validateUserCity(); fieldTouched.userCity = true;">
            <p class="text-statusError text-body-4 mt-2" v-if="errors.userCity && fieldTouched.userCity">{{ errors.userCity }}</p>
        </div>
        <div class="flex flex-col gap-y-2">
            <label class="text-body-4 block text-label" for="userCountry">
                Ország*
            </label>
            <select id="userCountry" class="input-with-border w-full" name="userCountry">
                <option v-for="c of countries" :value="c" :selected="c === userData.userCountry || (! userData.userCountry && c === 'Magyarország' )">{{ c }}</option>
            </select>
        </div>
        <div class="flex flex-col gap-y-2">
            <label class="text-body-4 block text-label" for="userOccupation">
                Foglalkozás
                <span class="text-inverse-secondary">(opcionális)</span>
            </label>
            <input class="input-with-border w-full" id="userOccupation" name="userOccupation" type="text">
        </div>
    </div>
    <div class="lg:col-span-3 lg:col-end-3 flex flex-col gap-6 lg:flex-row">
        <button class="btn w-full" :disabled="! formValid">Fotó + történet feltöltése</button>
        <a class="lg:hidden btn-link" href="#" @click.prevent="prevStep">
            <span class="icon">
                <svg width="16" height="17" viewbox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73428 7.73077L13 7.73077L13 9.26923L5.73428 9.26923L8.65286 12.4123L7.64286 13.5L3 8.5L7.64286 3.5L8.65286 4.58769L5.73428 7.73077Z" fill="currentColor"/>
                </svg>
            </span>
            <span>Vissza</span>
        </a>
    </div>
</template>

<script>
export default {
    name: "storyUploadStep4"
}
</script>

<script setup>
import {computed, ref} from "vue";

const props = defineProps(['appRoot']);
const { appRoot } = props;

const countries = JSON.parse(appRoot.dataset.countries || '[]');

const userData = ref(JSON.parse(appRoot.dataset.userData || '{}'));
if (! userData.value.userCity) {
    userData.value.userCity = '';
}
if (! userData.value.userCountry) {
    userData.value.userCountry = '';
}
if (! userData.value.userOccupation) {
    userData.value.userOccupation = '';
}

const errors = ref({});
const fieldTouched = ref({});

const formValid = computed(() => {
    runFormValidations();
    return Object.keys(errors.value).length === 0;
});

function prevStep() {
    const formElem = document.querySelector('form.prev-step');
    if (formElem) {
        formElem.submit();
    }
}

function runFormValidations() {
    validateUserCity();
}

function validateUserCity() {
    delete errors.value.userCity;
    if (userData.value.userCity.trim() === '') {
        errors.value.userCity = 'Kötelező mező.';
    }
}

</script>
