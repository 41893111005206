<template>

    <div class="timeline relative">
        <div class="timeline-line absolute left-0 right-0 bottom-1.5 bg-current border-b border-b-current"></div>
        <div class="container">
            <div class="timeline-contents relative h-8 lg:h-9 pb-1.5 text-body-4 lg:text-body-3 font-medium">
                <div class="relative w-full h-full">
                    <div class="absolute top-px left-0 h-6 lg:h-7 w-12 lg:w-14 grid place-items-center shadow-inset-etno-red select-none">
                        {{ minYear }}
                    </div>
                    <div class="absolute top-px right-0 h-6 lg:h-7 w-12 lg:w-14 grid place-items-center select-none">
                        {{ maxYear }}
                    </div>
                    <div
                         :style="{ '--l': hoverPercentage + '%' }"
                         :class="{ 'opacity-0': ! hoverVisible || isMoving, 'opacity-100': hoverVisible && ! isMoving, 'transition-all': hoverAnimatable, 'transition-opacity': ! hoverAnimatable }"
                         class="absolute top-px left-[--l] -translate-x-[--l] h-6 lg:h-7 w-12 lg:w-14 grid place-items-center select-none bg-gray-100 dark:bg-gray-800 text-primary duration-150">
                        {{ hoverYear }}
                    </div>
                    <div :style="{ '--slider' : sliderPercentage + '%' }"
                         @mousemove="hoverMove($event)"
                         @mouseleave="hoverLeave"
                         @click="moveSliderTo(hoverYear)"
                         class="relative h-full timeline-slider">
                        <div class="line w-[--slider] absolute h-0.5 bottom-0 bg-etno-red transition-all duration-150 z-10"></div>
                        <div @mousedown.prevent="onMouseDown($event)"
                             @touchstart.prevent="onMouseDown($event)"
                             @mousemove.stop
                             :class="{ 'cursor-grab': isInteractive && ! isMoving, 'cursor-grabbing': isInteractive && isMoving }"
                             class="slider-handle absolute top-px left-[--slider] h-6 lg:h-7 w-12 lg:w-14 grid place-items-center bg-etno-red text-white -translate-x-[--slider] select-none transition-all duration-150 after:absolute after:h-px after:-bottom-px after:bg-etno-red after:inset-x-0">
                            {{ sliderValue }}
                        </div>
                    </div>
                </div>
                <div class="timeline-notches h-1.5">
                    <div class="h-full mx-6 lg:mx-7 relative">
                        <template v-for="(_, diff) in (maxYear - minYear)">
                            <div v-if="(minYear + diff) % 5 === 0"
                                 class="notch h-full absolute left-[--l] border-r border-r-current w-0"
                                 :style="{ '--l': (diff / (maxYear - minYear) * 100) + '%' }"
                            ></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Timeline"
}
</script>

<script setup>
import { ref } from 'vue';

const props = defineProps(['minYear', 'maxYear', 'value', 'isInteractive', 'appContainer']);
const emit = defineEmits(['change']);

const step = 1; // could be a prop, if needed
const appContainer = props.appContainer;
const sliderValue = ref(props.value);
const sliderPercentage = ref( (props.value - props.minYear) / (props.maxYear - props.minYear) * 100 );
const isMoving = ref(false);
const hoverVisible = ref(false);
const hoverYear = ref(0);
const hoverPercentage = ref(0);
const hoverAnimatable = ref(false);

let startX = 0;
let mouseMoveCounter = 0;
let barValue, barBox;

function onMouseDown(e) {
    if (! props.isInteractive) {
        return;
    }
    startX = e.clientX;
    if (e.type === "touchstart") {
        if (e.touches.length === 1) {
            startX = e.touches[0].clientX;
        } else {
            return;
        }
    }
    mouseMoveCounter = 0;
    barValue = sliderValue.value;
    barBox = e.target.parentNode.getBoundingClientRect();
    document.addEventListener("mousemove", onMousemove);
    document.addEventListener("mouseup", onMouseup);
    document.addEventListener("touchmove", onMousemove);
    document.addEventListener("touchend", onMouseup);
    isMoving.value = true;
}

function onMousemove(e) {
    mouseMoveCounter++;
    let clientX = e.clientX;
    if (e.type === "touchmove") {
        clientX = e.touches[0].clientX;
    }
    let per = (clientX - startX) / barBox.width;
    let val = barValue + (props.maxYear - props.minYear) * per;
    let mod = val % step;
    val -= mod;
    if (val < props.minYear) {
        val = props.minYear;
    } else if (val > props.maxYear) {
        val = props.maxYear;
    }
    sliderValue.value = val;
    sliderPercentage.value = (sliderValue.value - props.minYear) / (props.maxYear - props.minYear) * 100;
}

function onMouseup() {
    document.removeEventListener("mousemove", onMousemove);
    document.removeEventListener("mouseup", onMouseup);
    document.removeEventListener("touchmove", onMousemove);
    document.removeEventListener("touchend", onMouseup);
    isMoving.value = false;
    valueChanged();
}

function hoverMove(e) {
    if (! props.isInteractive) {
        return;
    }
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const ratio = Math.max(0, Math.min(1, x / rect.width));
    const v = ((props.maxYear + 1 - props.minYear) * ratio + props.minYear);
    hoverVisible.value = true;
    hoverYear.value = v - (v % step);
    hoverPercentage.value = (hoverYear.value - props.minYear) / (props.maxYear - props.minYear) * 100;
    if (! hoverAnimatable.value) {
        setTimeout(() => {
            hoverAnimatable.value = true;
        }, 20);
    }
}

function hoverLeave() {
    if (! props.isInteractive) {
        return;
    }
    hoverVisible.value = false;
    hoverAnimatable.value = false;
}

function moveSliderTo(year) {
    if (! props.isInteractive) {
        return;
    }
    sliderValue.value = year;
    sliderPercentage.value = (sliderValue.value - props.minYear) / (props.maxYear - props.minYear) * 100;
    valueChanged();
}

function valueChanged() {
    emit('change', sliderValue.value);
    dispatchCustomEvent('timelineChange', { value: sliderValue.value });
}

function dispatchCustomEvent(eventName, eventData) {
    if (!appContainer) return;
    const event = new CustomEvent(eventName, {
        detail: eventData,
        bubbles: true,
        cancelable: true,
    });
    appContainer.dispatchEvent(event);
}
</script>
