export function initMobileMenu() {
    const mobileMenuButton = document.getElementById('mobile-menu-button');
    const mobileMenuCloseButton = document.getElementById('close-mobile-menu');
    const header = document.querySelector('header');
    if (header && mobileMenuButton) {
        mobileMenuButton.addEventListener('click', e => {
            e.preventDefault();
            document.body.classList.add('mobile-menu-visible');
        });
    }
    if (header && mobileMenuCloseButton) {
        mobileMenuCloseButton.addEventListener('click', e => {
            e.preventDefault();
            document.body.classList.remove('mobile-menu-visible');
            // TODO tab trap?
        });
    }
}

export function initUserMenu() {
    const button = document.getElementById('user-menu-button');
    if (! button) {
        return;
    }
    button.addEventListener('click', e => {
        e.preventDefault();
        document.body.classList.toggle('user-menu-visible');
    });
    const mobileMenuCloseButton = document.getElementById('close-mobile-user-menu');
    const header = document.querySelector('header');
    if (header && mobileMenuCloseButton) {
        mobileMenuCloseButton.addEventListener('click', e => {
            e.preventDefault();
            document.body.classList.remove('user-menu-visible');
            // TODO tab trap?
        });
    }
}
