export function initVideoBlocks() {
    const youTubeIframeReady = new Promise(function(resolve){
        document.addEventListener('onYouTubeIframeAPIReady', resolve);
    });
    window.onYouTubeIframeAPIReady = function() {
        document.dispatchEvent(new CustomEvent('onYouTubeIframeAPIReady', {}));
    };
    Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
        get: function(){
            return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
        },
        configurable: true
    });

    const youtubeMap = new Map();
    const videoBlocks = document.querySelectorAll('section.video');
    for (const videoBlock of videoBlocks) {
        const playButton = videoBlock.querySelector('.video-play-button');
        const youtubeEmbedPlaceholder = videoBlock.querySelector('[data-youtube-id]');
        if (playButton) {
            playButton.addEventListener('click', (e) => {
                e.preventDefault();
                playButton.classList.add('hidden');
                const videoCover = videoBlock.querySelector('.video-cover');
                setTimeout(() => { videoCover.classList.add('hidden'); }, 250);
                const iframeElement = videoBlock.querySelector('iframe');
                const youtubeEventTarget = youtubeMap.get(iframeElement.dataset.youtubeId);
                if (youtubeEventTarget) {
                    youtubeEventTarget.playVideo();
                }
                if (iframeElement) {
                    iframeElement.classList.remove('hidden');
                }
            });
        }
        if (youtubeEmbedPlaceholder) {
            const youtubeId = youtubeEmbedPlaceholder.dataset.youtubeId;
            const placeholderId = youtubeEmbedPlaceholder.id;
            youTubeIframeReady.then(() => {
                new YT.Player(placeholderId, {
                    videoId: youtubeId,
                    payerVars: {
                        playsinline: 1,
                        modestbranding: 1,
                        rel: 0,
                    },
                    events: {
                        onReady: (event) => {
                            youtubeMap.set(event.target.getVideoData().video_id, event.target);
                        },
                        onStateChange: (event) => {
                            const iframe = videoBlock.querySelector('iframe');
                            iframe.dataset.playing = event.data === YT.PlayerState.PLAYING ? '1' : '0';
                            if (event.data === YT.PlayerState.ENDED) {
                                const targetKeys = Object.keys(event.target);
                                let iframe = null;
                                for (const targetKey of targetKeys) {
                                    if (event.target[targetKey].constructor.name === 'HTMLIFrameElement') {
                                        iframe = event.target[targetKey];
                                    }
                                }
                                if (iframe) {
                                    iframe.classList.add('hidden');
                                    iframe.parentElement.querySelector('.video-cover').classList.remove('hidden');
                                    iframe.parentElement.querySelector('.video-play-button').classList.remove('hidden');
                                }
                            }
                        },
                    }
                });
            });
        }
    }

    if (window.YT && typeof window.YT.Player === 'function') {
        window.onYouTubeIframeAPIReady();
    }
}
