import {addTrapHandler, clearTrapHandler, setTabindices} from "./tab-trap";
import {createApp} from "vue";
import HeaderSearchAutocomplete from "../vue/header-search-autcomplete.vue";

export function initHeaderSearch() {
    const searchButton = document.querySelector('header .search-button');
    const searchWrapper = document.getElementById('search-wrapper');
    const searchInput = document.getElementById('header-search');
    const searchAutocomplete = document.getElementById('header-search-autocomplete');
    setTabindices(searchWrapper, '-1');
    if (searchButton) {
        searchButton.addEventListener('click', e => {
            e.preventDefault();
            document.body.classList.add('search-visible', 'scrim-visible');
            searchInput.value = '';
            setTimeout(() => {
                if (searchInput) {
                    searchInput.focus();
                }
            }, 200);
            addTrapHandler(searchWrapper);
            setTabindices(searchWrapper, 0);
        });
    }

    const searchCloseButton = document.querySelector('#search-wrapper .close');
    if (searchCloseButton) {
        searchCloseButton.addEventListener('click', e => {
            e.preventDefault();
            document.body.classList.remove('search-visible', 'scrim-visible');
            clearTrapHandler(searchWrapper);
            setTabindices(searchWrapper, '-1');
        });
    }

    if (searchInput) {
        searchInput.addEventListener('keydown', e => {
            console.log(e.key);
            if (e.key.toLowerCase() === 'enter') {
                window.location.href = '/tortenetek?tags=' + searchInput.value;
            }
        });
    }

    createApp(HeaderSearchAutocomplete).mount(searchAutocomplete);
}
