export function initStoryScrollToStory() {
    const scrollButton = document.querySelector('.story-scroll-link a');
    if (!scrollButton) {
        return;
    }
    const contentElement = document.querySelector('.story-content');
    scrollButton.addEventListener('click', e => {
        e.preventDefault();
        contentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    })
}

export function initStoryDesktopIndexAnimation() {
    const storyGallery = document.querySelector('.story-gallery');
    const desktopIndexWrapper = document.querySelector('.desktop-index-image-wrapper');
    if (! storyGallery || ! desktopIndexWrapper) {
        return;
    }
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            desktopIndexWrapper.classList.toggle('opacity-100', ! entry.isIntersecting);
            desktopIndexWrapper.classList.toggle('translate-0', ! entry.isIntersecting);
            desktopIndexWrapper.classList.toggle('opacity-0', entry.isIntersecting);
            desktopIndexWrapper.classList.toggle('-translate-y-10', entry.isIntersecting);
        });
    }, {
        root: null,
        rootMargin: '0px',
        threshold: 0
    });
    observer.observe(storyGallery);
}
