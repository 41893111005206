export function initPhotoCollage() {
    const photoCollage = document.querySelector('.photo-collage');
    if (! photoCollage) {
        return;
    }
    const columns = [...photoCollage.querySelectorAll('.col')];

    const images = JSON.parse(photoCollage.dataset.images);
    if (images.length < 30) {
        return;
    }

    shuffleArray(images);
    const imageSets = getRandomUniqueImageSets(images, columns.length);

    for (const i in imageSets) {
        const set = imageSets[i];
        const col = columns[i];
        for (const setImage of set) {
            col.innerHTML += setImage;
        }
    }

    // on mobile scroll a bit to the right on init
    if (window.innerWidth < 768) {
        photoCollage.parentElement.scrollTo({ top: 0, left: photoCollage.firstElementChild.offsetWidth / 2, behavior: 'auto' });
    }
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
}

function getRandomUniqueImageSets(images, setsCount = 10, setSize = 3) {
    const allImages = [...images]; // Clone the array to avoid modifying the original
    const selectedSets = [];

    for (let i = 0; i < setsCount; i++) {
        const set = [];
        for (let j = 0; j < setSize; j++) {
            if (allImages.length === 0) {
                throw new Error("Not enough images to complete the selection.");
            }
            const randomIndex = Math.floor(Math.random() * allImages.length);
            set.push(allImages.splice(randomIndex, 1)[0]); // Remove the selected image
        }
        selectedSets.push(set);
    }

    return selectedSets;
}
