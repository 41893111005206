import {throttle} from "./header-scroll";

let itemCounter = 0;

export function initMasonryBlocks() {
    const masonryBlocks = document.querySelectorAll('.masonry-wrapper');
    for (const masonryBlock of masonryBlocks) {
        initMasonryBlock(masonryBlock);
    }
}

export function initMasonryBlock(masonryBlock, attachResizeHandler = true) {
    const cols = [... masonryBlock.querySelectorAll('.col')];
    const items = [... masonryBlock.querySelectorAll('.masonry-item')];

    for (const item of items) {
        if (item.dataset.masonryId !== undefined) {
            item.id = 'masonry-item-' + item.dataset.masonryId;
            itemCounter = Math.max(itemCounter, parseInt(item.dataset.masonryId) + 1);
        }
    }
    for (const item of items) {
        if (item.dataset.masonryId === undefined) {
            item.dataset.masonryId = itemCounter;
            item.id = 'masonry-item-' + itemCounter;
            itemCounter++;
        }
    }

    items.sort( (el1, el2) => parseInt(el1.dataset.masonryId) < parseInt(el2.dataset.masonryId) ? -1 : 1 );

    masonryBlock.removeEventListener('load', imageLoadHandler, true);
    masonryBlock.addEventListener('load', imageLoadHandler, true);

    if (attachResizeHandler) {
        window.addEventListener('resize', throttle(() => {
            reLayout(masonryBlock, items, cols);
        }));
    }
    reLayout(masonryBlock, items, cols);

    function imageLoadHandler() {
        reLayout(masonryBlock, items, cols);
    }
}

export function resetItemCounter() {
    itemCounter = 0;
}

export function externalWindowResized(masonryBlock) {
    const cols = [... masonryBlock.querySelectorAll('.col')];
    let i;
    const items = [... masonryBlock.querySelectorAll('.masonry-item')];
    items.forEach(el => el.id = el.id || ('z-' + i++));
    items.sort( (el1, el2) => el1.id < el2.id ? -1 : 1 );
    reLayout(masonryBlock, items, cols);
}

function reLayout(masonryBlock, items, cols) {
    const visibleColCount = cols.map(col => col.offsetParent).filter(e => !! e).length;
    const currentLayout = masonryBlock.dataset.layout || '[]';
    const newLayout = createMasonryLayout(items, visibleColCount);
    const newLayoutString = JSON.stringify(newLayout);
    if (currentLayout !== newLayoutString) {
        const itemStorage = document.createElement('div');
        const storageMap = new Map();
        // take out each item
        for (const item of items) {
            itemStorage.prepend(item);
            storageMap.set(item.id, item);
        }
        // append them back according to newLayout
        for (let c = 0; c < newLayout.length; c++) {
            for (const itemId of newLayout[c]) {
                cols[c].append(storageMap.get(itemId));
            }
        }
        // save state
        masonryBlock.dataset.layout = newLayoutString;
    }
}

export function createMasonryLayout(items, colCount) {
    const columns = Array.from({ length: colCount }, () => []);
    const columnHeights = Array.from({ length: colCount }, () => 0);

    items.forEach((item, index) => {
        let shortestColIndex;
        if (index === items.length - 1 && colCount === 2 && Math.abs(columnHeights[0] - columnHeights[1]) <= 8) {
            shortestColIndex = 0; // Always place in the left column
        } else {
            shortestColIndex = columnHeights.indexOf(Math.min(...columnHeights));
        }
        columns[shortestColIndex].push(item.id);
        columnHeights[shortestColIndex] += item.offsetHeight;
    });

    return columns;
}
