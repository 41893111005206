export function initPasswordFieldVisibilityButtons() {
    const buttons = document.querySelectorAll('input[type="password"] + .toggle-password');
    for (const button of buttons) {
        button.addEventListener('click', toggleInputType);
    }
}

function toggleInputType(e) {
    const button = e.currentTarget;
    const input = button.parentElement.querySelector('input');
    input.type = input.type !== 'password' ? 'password' : 'text';
}
