<template>
    <div class="aspect-square w-full dark:bg-gray-800 bg-gray-100 flex flex-col justify-center items-center gap-4 @container">
        <div class="rounded-full w-12 h-12 @[6rem]:w-18 @[6rem]:h-18 border-4 border-etno-red grid place-items-center text-body-1">18</div>
        <div class="gap-2 hidden @[15rem]:flex flex-col items-center">
            <span class="text-body-2 text-gray-600 dark:text-gray-300">Korhatáros tartalom</span>
            <button class="btn-link h-auto px-0 pointer-events-auto" title="Korhatáros tartalom megtekintése" @click="buttonClick">
                <span>Megtekintés</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "adultContentPlaceholder"
}
</script>

<script setup>

const emit = defineEmits(['showPopup']);

function buttonClick() {
    emit('showPopup');
}

</script>
