export async function getAutoSuggestList(keyword) {
    const responseObj = await fetch('/jelen/search-autocomplete', {
        method: 'POST',
        headers: {
            'X-CSRF-Token' : window.csrfTokenValue,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ s: keyword }),
    });
    return await responseObj.json();
}
