export function initUserTabbing() {
    window.addEventListener("keydown", handleFirstTab);
}

function handleFirstTab(e) {
    if (e.keyCode === 9) {
        document.documentElement.classList.add("user-is-tabbing");
        window.removeEventListener("keydown", handleFirstTab);
        window.addEventListener("mousedown", handleMouseDownOnce);
    }
}

function handleMouseDownOnce() {
    document.documentElement.classList.remove("user-is-tabbing");
    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
}
