export const throttle = (func, time = 50) => {
	let lastTime = 0;
	return () => {
		const now = new Date();
		if (now - lastTime >= time) {
			func();
			lastTime = now;
		}
	};
};

export function initHeaderScroll() {
	const header = document.getElementById('header');
	let lastScroll = 0;
	window.addEventListener('scroll', throttle(validateHeader));

	function validateHeader() {
		if (window.scrollY < lastScroll) {
			header.classList.add('scroll-up');
			document.body.classList.add('header-scroll-up');
		} else {
			header.classList.remove('scroll-up');
			document.body.classList.remove('header-scroll-up');
		}

		lastScroll = window.scrollY;

        if (window.scrollY > 128) {
            header.classList.add('below-headers', 'between-headers');
        } else if (window.scrollY > 64) {
            header.classList.add('between-headers');
            header.classList.remove('below-headers');
        } else {
			header.classList.remove('below-headers', 'between-headers');
		}
	}
}
