export function initAccordions() {
    const accordionButtons = document.querySelectorAll('.accordion-button');
    for (const accordionButton of accordionButtons) {
        accordionButton.addEventListener('click', e => {
            e.preventDefault();
            const content = accordionButton.nextElementSibling;
            if (content.classList.contains('animating')) {
                return;
            }
            const accordionButtonIcon = accordionButton.querySelector('span');
            if (content.classList.contains('invisible')) {
                accordionButton.classList.add('open');
                content.style.setProperty('--collapse-height', `${content.scrollHeight}px`);
                content.classList.remove('invisible', 'max-h-0');
                content.classList.add('animating', 'max-h-[--collapse-height]');
                setTimeout(() => {
                    content.classList.remove('animating', 'max-h-[--collapse-height]');
                }, 300);
                if (accordionButtonIcon && accordionButtonIcon.classList.contains('chevron')) {
                    accordionButtonIcon.classList.add('-rotate-180');
                }
            } else {
                accordionButton.classList.remove('open');
                content.style.setProperty('--collapse-height', `${content.scrollHeight}px`);
                content.classList.add('max-h-[--collapse-height]');
                setTimeout(() => {
                    content.classList.remove( 'max-h-[--collapse-height]');
                    content.classList.add('animating', 'max-h-0');
                    setTimeout(() => {
                        content.classList.add('invisible');
                        content.classList.remove('animating');
                    }, 300);
                }, 0);
                if (accordionButtonIcon && accordionButtonIcon.classList.contains('chevron')) {
                    accordionButtonIcon.classList.remove('-rotate-180');
                }
            }
        });
    }
}
