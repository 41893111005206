<template>
    <li v-for="result in searchResults">
        <a :href="result.href" class="block px-6 py-3 hover:bg-gray-50 dark:hover:bg-gray-950">{{ result.text }}</a>
    </li>
</template>

<script>
export default {
    name: "headerSearchAutocomplete"
}
</script>

<script setup>
    import {ref} from "vue";
    import {addTrapHandler, clearTrapHandler} from "../js/tab-trap";
    import {getAutoSuggestList} from "./helpers/auto-suggest";

    const searchValue = ref('');
    const searchInput = document.getElementById('header-search');
    const searchWrapper = document.getElementById('search-wrapper');
    const searchResults = ref([]);

    let debounceTimer;
    searchInput.addEventListener('input', () => {
        searchValue.value = searchInput.value;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            startSearch();
        }, 300);
    });

    async function startSearch() {
        const response = await getAutoSuggestList(searchValue.value);
        if (response.status) {
            searchResults.value = response.tags
                .map(tag => ({text: tag, href: '/tortenetek?tags=' + encodeURIComponent(tag)}));
        }
    }
</script>
