<template>
    <div class="offcanvas fixed right-0 top-0 bottom-0 translate-x-full z-100 flex flex-col gap-4 lg:gap-10 w-[25rem] max-w-full invisible bg-clip-padding outline-0 transition-transform duration-400 ease-out overflow-y-auto bg-white dark:bg-gray-950"
         :class="{ show: showClass, showing: showingClass, hiding: hidingClass }"
         tabindex="-1" aria-modal="true" role="dialog" aria-labelledby="filters-offcanvas-title">
        <div class="offcanvas-header sticky top-0 bg-white dark:bg-gray-950 py-4 lg:py-5 px-6 lg:px-10 relative z-10">
            <div class="flex items-center">
                <div class="relative grow">
                    <h3 class="text-headline-5 lg:text-headline-4 transition-all duration-500 lg:line-clamp-1" id="filters-offcanvas-title">
                        Szűrés
                    </h3>
                </div>
                <button class="close btn-link h-6 lg:h-10 shrink-0 px-0" @click="emit('close')">
                    <span class="icon hidden lg:block">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33333 8.62957L11.7037 11.9999L12.6667 11.037L9.29628 7.66662L12.6667 4.29621L11.7037 3.33325L8.33333 6.7036L4.96302 3.33325L4 4.29621L7.37045 7.66662L4.00014 11.037L4.96308 11.9999L8.33333 8.62957Z" fill="currentColor"/>
                    </svg>
                </span>
                    <span class="icon block lg:hidden">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 12.9445L17.5556 18L19 16.5556L13.9444 11.5001L19 6.44443L17.5556 5L12.5 10.0555L7.44452 5L6 6.44443L11.0557 11.5001L6.0002 16.5556L7.44463 18L12.5 12.9445Z" fill="currentColor"/>
                    </svg>
                </span>
                </button>
            </div>
        </div>
        <div class="offcanvas-body grow p-0 overflow-y-visible px-6 lg:px-10 flex flex-col">

            <a class="accordion-button flex justify-between items-center py-6 gap-4 open" href="#" role="button">
                <h3 class="text-body-3">Gyűjtemény</h3>
                <span class="shrink-0 chevron transition-transform duration-300 -rotate-180">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 5L13 9.8213L11.778 11L8.00004 7.35706L4.22205 11L3 9.8213L8.00004 5Z" fill="currentColor"/>
                    </svg>
                </span>
            </a>
            <div class="overflow-clip -m-0.5 p-0.5 transition-all duration-300" aria-hidden="true">
                <div class="flex flex-col items-start gap-4 text-body-3">
                    <label class="relative flex items-center cursor-pointer gap-2" v-for="(campaign, i) in campaigns">
                        <input type="checkbox" class="input-checkbox peer" v-model="selectedCampaigns[i]"/>
                        <span class="input-checkbox-checkmark">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22 2H2V22H22V2ZM6.414 10.172L10.414 14.172L17.586 7L19 8.414L10.414 17L5 11.586L6.414 10.172Z" fill="currentColor"/>
                            </svg>
                        </span>
                        {{ campaign.title }}
                    </label>
                </div>
            </div>

        </div>
        <div class="offcanvas-footer grow-0 pb-6 lg:pb-10 px-6 lg:px-10 flex justify-between items-center">
            <a href="#" class="btn-link px-0" @click.prevent="deleteFilters()">
                <span>Összes törlése</span>
            </a>
            <button class="btn" @click.prevent="doFilter()">
                Szűrés
                <span class="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2657 8.76923H3V7.23077H10.2657L7.34714 4.08769L8.35714 3L13 8L8.35714 13L7.34714 11.9123L10.2657 8.76923Z" fill="currentColor"/>
                    </svg>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "filtersOffcanvas"
}
</script>

<script setup>

import {ref, watch} from "vue";

const props = defineProps(['isOpen', 'campaigns', 'initialFilters']);
const emit = defineEmits(['close', 'changeFilters']);
const showClass = ref(false);
const showingClass = ref(false);
const hidingClass = ref(false);
const scrim = document.getElementById('scrim-over-header');

const selectedCampaigns = ref(props.campaigns.map(campaign => (props.initialFilters.campaignIds || []).includes(campaign.id)));

watch(() => props.isOpen, () => {
    // TODO trap handler
    if (props.isOpen) {
        showingClass.value = true;
        setTimeout(() => {
            showingClass.value = false;
            showClass.value = true;
        }, 300);
        document.body.classList.add('scrim-over-header-visible', 'overflow-hidden');
        scrim.addEventListener('click', scrimClickHandler);
    } else {
        hidingClass.value = true;
        setTimeout(() => {
            hidingClass.value = false;
            showClass.value = false;
        }, 300);
        document.body.classList.remove('scrim-over-header-visible', 'overflow-hidden');
        scrim.removeEventListener('click', scrimClickHandler);
    }
});

function scrimClickHandler() {
    emit('close');
}

function deleteFilters() {
    selectedCampaigns.value = selectedCampaigns.value.map(_ => false);
}

function doFilter() {
    const campaignIds = [];
    for (let i = 0; i < props.campaigns.length; i++) {
        if (selectedCampaigns.value[i]) {
            campaignIds.push(props.campaigns[i].id);
        }
    }
    emit('changeFilters', { campaignIds });
    emit('close');
}

</script>
