import {isAdultPopupAccepted, showAdultPopup} from "./adult-content";

export function initStoryAdultContent() {
    document.body.addEventListener('click', e => {
        const btn = e.target.closest('button');
        if (btn && btn.classList.contains('enable-adult-content')) {
            e.preventDefault();
            const swiperSlide = e.target.closest('.swiper-slide');
            if (swiperSlide && swiperSlide.dataset.imageId) {
                showImageOrPopup(swiperSlide.dataset.imageId);
            }
        }
    });
}

function showImageOrPopup(imageId) {
    // TODO: save the selection maybe?
    if (isAdultPopupAccepted()) {
        const sameImageElements = document.querySelectorAll(`.is-adult-content[data-image-id="${imageId}"]`);
        for (const elem of sameImageElements) {
            elem.classList.remove('is-adult-content');
            if (elem.classList.contains('swiper-slide')) {
                const blocker = elem.querySelector('.adult-content-blocker');
                if (blocker) {
                    blocker.remove();
                }
            }
        }
    } else {
        showAdultPopup( () => { showImageOrPopup(imageId); } );
    }
}
