import "./app.scss";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

import {initBodyClass} from "./js/init";
import {initUserTabbing} from "./js/user-tabbing";
import {initHeaderScroll} from "./js/header-scroll";
import {initTimelineSliders} from "./js/timeline-app";
import {initAccordions} from "./js/accordion";
import {initPhotoCollage} from "./js/photo-collage";
import {initMobileMenu, initUserMenu} from "./js/mobile-menu";
import {initMasonryBlocks} from "./js/masonry-block";
import {initHeaderSearch} from "./js/header-search";
import {initTooltips} from "./js/tooltips";
import {initStoryGallery, initStoryLightboxGallery} from "./js/story-gallery";
import {initStoryDesktopIndexAnimation, initStoryScrollToStory} from "./js/story-page";
import {initOffcanvasLinks} from "./js/offcanvas";
import {initPasswordFieldVisibilityButtons} from "./js/password-field-toggle";
import {initStoryUploadStep2} from "./js/story-upload-step-2";
import {initStoriesSearch} from "./js/stories-search";
import {initStoryAdultContent} from "./js/story-adult-content";
import {initAdultPopup} from "./js/adult-content";
import {initMyStoriesInfiniteScroll} from "./js/my-stories-infinite-scroll";
import {initStoryUploadStep3} from "./js/story-upload-step-3";
import {initStoryUploadStep4} from "./js/story-upload-step-4";
import {initStoryUploadDraftControls} from "./js/story-upload-draft-controls";
import {initDisableRightClick} from "./js/disable-right-click";
import {initTimelineBlock} from "./js/timeline-block";
import { initVideoBlocks } from "./js/video-block";

addEventListener('DOMContentLoaded', () => {
    initBodyClass();
    initUserTabbing();
    initDisableRightClick();
    initHeaderScroll();
    initHeaderSearch();
    initTimelineSliders();
    initStoriesSearch();
    initAccordions();
    initVideoBlocks();
    initPhotoCollage();
    initMobileMenu();
    initUserMenu();
    initMasonryBlocks();
    initTimelineBlock();
    initTooltips();
    initStoryGallery();
    initStoryScrollToStory();
    initStoryLightboxGallery();
    initStoryDesktopIndexAnimation();
    initPasswordFieldVisibilityButtons();
    initAdultPopup();
    initStoryAdultContent();
    initStoryUploadStep2();
    initStoryUploadStep3();
    initStoryUploadStep4();
    initStoryUploadDraftControls();
    initMyStoriesInfiniteScroll();
    initOffcanvasLinks();
})


// Only used in dev
if (module.hot) {
    module.hot.accept();
}
