<template>
    <a v-for="(result, idx) of results"
       :href="result.url"
       class="col-span-4 lg:col-span-6 grid grid-cols-subgrid items-center gap-y-4"
       :class="{ 'mt-10': idx !== 0 }">
        <div class="self-start lg:self-center relative">
            <img v-if="! result.adultContent || canViewAdultContent" :src="result.images.list" :alt="result.title">
            <adult-content-placeholder v-else @showPopup="showAdultContentPopup"></adult-content-placeholder>
        </div>
        <div class="col-span-3 lg:col-span-2 flex flex-col justify-center lg:gap-0.5">
            <div class="text-body-4 font-medium lg:text-body-3">{{ result.year }}</div>
            <div class="text-body-2 font-medium lg:text-body-1">{{ result.title }}</div>
            <div class="text-body-4 font-medium lg:text-body-3 text-secondary">{{ result.location }}</div>
        </div>
        <div class="col-span-4 lg:col-span-3 text-body-3 lg:text-body-2 line-clamp-6 lg:line-clamp-5">
            {{ result.description }}
        </div>
    </a>
</template>

<script>
export default {
    name: "results-list"
}
</script>

<script setup>

import {computed, watch} from "vue";
import {isAdultPopupAccepted} from "../../js/adult-content";
import AdultContentPlaceholder from "./adult-content-placeholder.vue";

const props = defineProps(['results']);

const canViewAdultContent = computed(() => {
    return isAdultPopupAccepted();
});

function showAdultContentPopup() {
    // TODO
}

</script>
