let deleteDraftPopup = null;
let saveDraftPopup = null;
let acceptCallback = null;
let denyCallback = null;

export function initStoryUploadDraftControls() {
    // header buttons, shown only if editing a saved draft
    const saveDraftButton = document.querySelector('.save-draft-trigger');
    if (saveDraftButton) {
        saveDraftButton.addEventListener('click', e => {
            e.preventDefault();
            showSaveDraftPopup(submitSaveDraftForm);
        });
    }
    const deleteDraftPopupButton = document.querySelector('.delete-draft-open-popup');
    if (deleteDraftPopupButton) {
        deleteDraftPopupButton.addEventListener('click', e => {
            e.preventDefault();
            showDeleteDraftPopup(submitDeleteDraftForm);
        });
    }

    saveDraftPopup = document.getElementById('footer-save-draft-popup');
    if (saveDraftPopup) {
        saveDraftPopup.querySelector('.save-draft-popup-close').addEventListener('click', () => {
            closeSaveDraftPopup();
            if (denyCallback && typeof denyCallback === 'function') {
                denyCallback();
            }
        });
        saveDraftPopup.querySelector('.save-draft-cancel').addEventListener('click', () => {
            closeSaveDraftPopup();
            if (denyCallback && typeof denyCallback === 'function') {
                denyCallback();
            }
        });
        saveDraftPopup.querySelector('.save-draft-accept').addEventListener('click', () => {
            closeSaveDraftPopup();
            if (acceptCallback && typeof acceptCallback === 'function') {
                acceptCallback();
            }
        });
    }

    deleteDraftPopup = document.getElementById('footer-delete-draft-popup');
    if (deleteDraftPopup) {
        deleteDraftPopup.querySelector('.delete-draft-popup-close').addEventListener('click', () => {
            closeDeleteDraftPopup();
            if (denyCallback && typeof denyCallback === 'function') {
                denyCallback();
            }
        });
        deleteDraftPopup.querySelector('.delete-draft-cancel').addEventListener('click', () => {
            closeDeleteDraftPopup();
            if (denyCallback && typeof denyCallback === 'function') {
                denyCallback();
            }
        });
        deleteDraftPopup.querySelector('.delete-draft-accept').addEventListener('click', () => {
            closeDeleteDraftPopup();
            if (acceptCallback && typeof acceptCallback === 'function') {
                acceptCallback();
            }
        });
    }

    // normal form buttons, showing the popup
    const saveAsDraftButtons = document.querySelectorAll('.save-as-draft-trigger');
    for (const saveAsDraftButton of saveAsDraftButtons) {
        saveAsDraftButton.addEventListener('click', e => {
            e.preventDefault();
            showSaveDraftPopup(submitSaveDraftForm);
        });
    }
}

function submitDeleteDraftForm() {
    const form = document.getElementById('delete-draft-form');
    if (form) {
        form.submit();
    }
}

function showDeleteDraftPopup(_acceptCallback = null, _denyCallback = null) {
    acceptCallback = _acceptCallback;
    denyCallback = _denyCallback;
    if (! deleteDraftPopup) {
        return;
    }
    document.body.classList.add('scrim-over-header-visible');
    deleteDraftPopup.classList.remove('translate-y-full', 'md:pointer-events-none', 'md:opacity-0');
}

function closeDeleteDraftPopup() {
    if (! deleteDraftPopup) {
        return;
    }
    document.body.classList.remove('scrim-over-header-visible');
    deleteDraftPopup.classList.add('translate-y-full', 'md:pointer-events-none', 'md:opacity-0');
}

function showSaveDraftPopup(_acceptCallback = null, _denyCallback = null) {
    acceptCallback = _acceptCallback;
    denyCallback = _denyCallback;
    if (! saveDraftPopup) {
        return;
    }
    document.body.classList.add('scrim-over-header-visible');
    saveDraftPopup.classList.remove('translate-y-full', 'md:pointer-events-none', 'md:opacity-0');
}

function closeSaveDraftPopup() {
    if (! saveDraftPopup) {
        return;
    }
    document.body.classList.remove('scrim-over-header-visible');
    saveDraftPopup.classList.add('translate-y-full', 'md:pointer-events-none', 'md:opacity-0');
}

function submitSaveDraftForm() {
    const saveDraftSubmitButton = document.querySelector('form button[name="saveAsDraft"]');
    if (saveDraftSubmitButton) {
        saveDraftSubmitButton.click();
    }
}
