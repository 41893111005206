<template>
    <div class="masonry-wrapper col-span-4 lg:col-span-6 flex gap-6 md:gap-10 lg:gap-20" ref="masonryWrapper">
        <template v-if="props.mode === 'small'">
            <div class="col basis-0 grow flex flex-col gap-6 md:gap-y-10 lg:gap-y-20">
                <item v-for="result of masonryLayout[0]"
                            @imageLoaded="calculateMasonryLayout"
                            :can-view-adult-content="canViewAdultContent" :result="result" :mode="props.mode"></item>
            </div>
            <div class="col basis-0 grow flex flex-col gap-6 md:gap-y-10 lg:gap-y-20">
                <item v-for="result of masonryLayout[1]"
                            @imageLoaded="calculateMasonryLayout"
                            :can-view-adult-content="canViewAdultContent" :result="result" :mode="props.mode"></item>
            </div>
            <div class="col basis-0 grow flex-col gap-6 md:gap-y-10 lg:gap-y-20 hidden lg:flex">
                <item v-for="result of masonryLayout[2]"
                            @imageLoaded="calculateMasonryLayout"
                            :can-view-adult-content="canViewAdultContent" :result="result" :mode="props.mode"></item>
            </div>
        </template>
        <template v-if="props.mode === 'large'">
            <div class="col basis-0 grow flex flex-col gap-6 md:gap-y-10 lg:gap-y-20">
                <item v-for="result of masonryLayout[0]"
                            @imageLoaded="calculateMasonryLayout"
                            :can-view-adult-content="canViewAdultContent" :result="result" :mode="props.mode"></item>
            </div>
            <div class="col basis-0 grow flex-col gap-6 md:gap-y-10 lg:gap-y-20 hidden lg:flex">
                <item v-for="result of masonryLayout[1]"
                            @imageLoaded="calculateMasonryLayout"
                            :can-view-adult-content="canViewAdultContent" :result="result" :mode="props.mode"></item>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "results-thumbs"
}
</script>

<script setup>

import {computed, nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import {createMasonryLayout} from "../../js/masonry-block";
import {throttle} from "../../js/header-scroll";
import {isAdultPopupAccepted} from "../../js/adult-content";
import Item from "./item.vue";

const props = defineProps(['results', 'mode']); // mode: small | large

const masonryWrapper = ref(null);
const cols = ref([]);

const visibleColCount = ref(3);
let masonryLayoutString = '[]';
const masonryLayout = ref([[], [], []]);
const resultMap = new Map();

onMounted(async () => {
    cols.value = [... (masonryWrapper.value).querySelectorAll('.col')];
    window.addEventListener('resize', throttledWindowResized);
    masonryLayout.value[0].push(...props.results);
    await nextTick();
    createIdResultMap();
    assignMasonryIds();
    await nextTick();
    calculateMasonryLayout();
});

onUnmounted(() => {
    window.removeEventListener('resize', throttledWindowResized);
});

function getItems() {
    return [... (masonryWrapper.value).querySelectorAll('.masonry-item')];
}

watch(() => props.results, async (newValue, oldValue) => {
    const oldIds = oldValue.map(r => r.id);
    const newIds = newValue.map(r => r.id);
    if (oldIds.every((element, index) => element === newIds[index])) {
        masonryLayout.value[0].push(... newValue.slice(oldValue.length));
    } else {
        masonryLayout.value = [[...newValue], [], []];
        masonryLayoutString = '[]';
    }
    createIdResultMap();
    assignMasonryIds();
    await nextTick();
    calculateMasonryLayout();
});

const throttledWindowResized = throttle(() => {
    calculateMasonryLayout();
});

const canViewAdultContent = computed(() => {
    return isAdultPopupAccepted();
});

function createIdResultMap() {
    resultMap.clear();
    for (const result of props.results) {
        resultMap.set(result.id, result);
    }
}

function assignMasonryIds() {
    let lastId = 0;
    for (const result of props.results) {
        if (result.masonryId && result.masonryId > lastId) {
            lastId = result.masonryId;
        }
    }
    for (const result of props.results) {
        if (result.masonryId === undefined) {
            result.masonryId = ++ lastId;
        }
    }
}

function calculateMasonryLayout() {
    const items = getItems().sort((el1, el2) => parseInt(el1.dataset.masonryId) < parseInt(el2.dataset.masonryId) ? -1 : 1);
    const visibleColCount = cols.value.map(col => col.offsetParent).filter(e => !! e).length;
    // console.log(items.map(el => el.id));
    // console.log(visibleColCount);
    const newLayout = createMasonryLayout(items, visibleColCount);
    const newLayoutString = JSON.stringify(newLayout);
    // console.log(newLayoutString);
    if (masonryLayoutString !== newLayoutString) {
        masonryLayoutString = newLayoutString;
        const itemsToPlace = new Map();
        for (const item of items) {
            itemsToPlace.set(item.id, item);
        }
        masonryLayout.value = [[], [], []];
        for (let j = 0; j < newLayout.length; j++) {
            const col = newLayout[j];
            for (const id of col) {
                const elem = itemsToPlace.get(id);
                const storyId = parseInt(elem.dataset.storyId);
                masonryLayout.value[j].push(resultMap.get( storyId ));
            }
        }
    }
    // console.log(masonryLayout.value[0][0].id, masonryLayout.value[0][1].id, masonryLayout.value[1][0].id, masonryLayout.value[1][1].id, masonryLayout.value[2][0].id, masonryLayout.value[2][1].id);
}

</script>
