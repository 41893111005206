import {createApp} from "vue";
import Timeline from "../vue/timeline.vue";

export function initTimelineSliders() {
    const timelineApps = document.querySelectorAll('.timeline-app');
    for (const timelineApp of timelineApps) {
        const { minYear, maxYear, value, isInteractive } = timelineApp.dataset;
        const props = {
            minYear: parseInt(minYear),
            maxYear: parseInt(maxYear),
            value: parseInt(value),
            isInteractive: isInteractive === 'true',
            appContainer: timelineApp,
        };
        createApp(Timeline, props).mount(timelineApp);
    }
}
