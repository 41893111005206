<template>
    <div class="h-px relative -top-px pointer-events-none" ref="sentinel"></div>
    <section class="sticky bg-white/95 dark:bg-black/95 backdrop-blur-lg pt-0 pb-4 -mb-4 transition-[top,padding] duration-300"
             ref="stickyElement" :class="{ 'pb-4 lg:pb-6 -top-10 pt-10' : stickyStuck, 'top-0': ! stickyStuck, 'z-20': ! searchInputHighlighted, 'z-100': searchInputHighlighted }">
        <div class="section-scrim absolute inset-0 z-20 transition-opacity duration-300 backdrop-blur-[2px] bg-black/20"
             :class="{ 'opacity-0 pointer-events-none': ! searchInputHighlighted, 'opacity-100 pointer-events-auto': searchInputHighlighted }"></div>
        <div class="relative transition-[padding-top] duration-300" :class="{ 'pt-2': stickyStuck }">
            <timeline :min-year="1990" :max-year="timelineMaxYear" :value="searchMaxYear" :is-interactive="true" @change="searchMaxYear = $event"></timeline>
        </div>
        <div ref="fortepanFooter" class="transition-all duration-300 overflow-hidden" :class="{ 'max-h-14 lg:max-h-9': ! stickyStuck, 'max-h-0 opacity-0': stickyStuck }"></div>
        <div class="container search-filters-wrapper flex flex-col gap-4 lg:flex-row justify-between lg:items-start transition-[margin-top] duration-300" :class="{ 'mt-6 lg:mt-14': ! stickyStuck, 'mt-4 lg:mt-6': stickyStuck }">
            <div class="search-wrapper flex flex-col gap-4 lg:gap-6 lg:grow max-w-[25rem]">
                <div class="search-field-wrapper relative flex gap-4 items-center" :class="{ 'z-100': searchString.length > 0 }">
                    <div class="absolute z-10 highlight-bg inset-0 -m-4 p-4 bg-white dark:bg-gray-950"
                         :class="{ 'opacity-0 pointer-events-none': ! searchInputHighlighted, 'opacity-100 pointer-events-auto': searchInputHighlighted }"></div>
                    <label for="story-search" class="relative z-20">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5659 17.9752C15.0317 19.2024 13.0856 19.9363 10.9681 19.9363C6.01517 19.9363 2 15.9211 2 10.9681C2 6.01517 6.01517 2 10.9681 2C15.9211 2 19.9363 6.01517 19.9363 10.9681C19.9363 13.0856 19.2024 15.0317 17.9751 16.5659L22 20.5908L20.5908 22L16.5659 17.9752ZM17.9433 10.9681C17.9433 14.8204 14.8204 17.9434 10.9681 17.9434C7.11583 17.9434 3.99292 14.8204 3.99292 10.9681C3.99292 7.11583 7.11583 3.99292 10.9681 3.99292C14.8204 3.99292 17.9433 7.11583 17.9433 10.9681Z" fill="currentColor"/>
                        </svg>
                    </label>
                    <div class="relative z-20 grow multistyle-placeholder">
                        <input id="story-search" type="text" role="searchbox" class="w-full bg-transparent text-body-2 text-primary h-7 relative"
                               @input="searchInputInput($event)"
                               @keydown="searchInputKeydown($event)"
                               pattern=".*\S.*" required placeholder=" " v-model="searchString">
                        <label for="story-search" class="absolute inset-0 pointer-events-none text-body-2 text-gray-400 lg:hidden">Keresés</label>
                        <label for="story-search" class="absolute inset-0 pointer-events-none text-body-2 text-gray-400 hidden lg:block">Adj meg egy vagy több keresőszót, helyszínt</label>
                        <button class="absolute right-0 top-1/2 -translate-y-1/2" @click="closeAutoSuggestionList" v-if="searchInputHighlighted">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00014 9.33327L10.6667 11.9997L12 10.6664L9.33346 8L12 5.33355L10.6667 4.00028L8.00014 6.66673L5.33332 4L4 5.33327L6.66682 8L4 10.6666L5.33332 12L8.00014 9.33327Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </div>
                    <ul class="absolute top-full inset-x-0 z-20 bg-white dark:bg-gray-950 mt-4 pt-2 -mx-4 flex flex-col border-t border-t-gray-100 dark:border-t-gray-800"
                        v-if="searchInputHighlighted && autoSuggestionList.length">
                        <li v-for="item of autoSuggestionList">
                            <a href="#" @click.prevent="addTag(item)"
                               class="block text-body-3 py-3 px-4 hover:bg-gray-100 dark:hover:bg-gray-800">
                                {{ item }}
                            </a>
                        </li>
                        <li v-if="autoSuggestionLoading">
                            <span class="block text-body-3 py-3 px-4">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.1299 12L16.7461 17.1114C15.5247 18.2151 13.9058 18.8873 12.1299 18.8873C8.3262 18.8873 5.24267 15.8037 5.24267 12C5.24267 8.19627 8.3262 5.11274 12.1299 5.11274L12.1299 12Z" fill="currentColor"/>
                                </svg>
                            </span>
                        </li>
                        <li v-if="! autoSuggestionLoading && autoSuggestionList.length === 0">
                        </li>
                    </ul>
                </div>
                <div class="flex items-center gap-2" v-if="tags.length > 0">
                    <span class="text-utility-tag flex items-center gap-1 bg-inverse text-inverse-primary py-1.5 pl-3 pr-2 whitespace-nowrap" v-for="(tag, i) of tags">
                        {{ tag }}
                        <button :title="`${tag} címke törlése`" @click="deleteTag(i)">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.79016 12L4 11.2098L7.19903 7.98919L4 4.79016L4.79016 4L8.01081 7.19903L11.2098 4L12 4.79016L8.80097 7.98919L12 11.2098L11.2098 12L8.01081 8.80097L4.79016 12Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </span>
                    <button class="btn-link h-auto px-0 ml-8 text-nowrap" @click="deleteAllTags()" v-if="tags.length > 1">
                        <span>Összes törlése</span>
                    </button>
                </div>
            </div>
            <div class="filters-view-wrapper flex items-center justify-between lg:justify-end lg:gap-8">
                <div class="flex items-center gap-2" v-if="showFiltersButton">
                    <button class="flex items-center gap-2" type="button" @click="openFiltersOffcanvas">
                        <span class="text-utility-button">Szűrés</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 5V4H5V5H3V7H5V8H7V7H21V5H7ZM10 17H21V19H10V20H8V19H3V17H8V16H10V17ZM19 11H21V13H19V14H17V13H3V11H17V10H19V11Z" fill="currentColor"/>
                        </svg>
                    </button>
                    <span class="hidden lg:grid rounded-full bg-inverse text-inverse-primary w-6 h-6 text-headline-xs font-medium place-items-center tracking-normal" v-if="filtersCount > 0">
                        {{ filtersCount }}
                    </span>
                </div>
                <div class="flex items-center gap-4">
                    <span class="text-utility-button mr-2">Nézet</span>
                    <div class="flex items-center gap-2" role="radiogroup">
                        <button role="radio" :aria-selected="viewMode === 'large'" :class="{'text-gray-300 dark:text-gray-600' : viewMode !== 'large'}" @click="viewMode = 'large'">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="block lg:hidden">
                                <path d="M4 4H20V20H4V4Z" fill="currentColor"/>
                            </svg>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="hidden lg:block">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 4H3V20H10V4ZM21 4H14V20H21V4Z" fill="currentColor"/>
                            </svg>
                        </button>
                        <button role="radio" :aria-selected="viewMode === 'small'" :class="{'text-gray-300 dark:text-gray-600' : viewMode !== 'small'}" @click="viewMode = 'small'">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="block lg:hidden">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 4H3V20H10V4ZM21 4H14V20H21V4Z" fill="currentColor"/>
                            </svg>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="hidden lg:block">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4H8V8H4V4ZM10 4H14V8H10V4ZM20 4H16V8H20V4ZM4 10H8V14H4V10ZM14 10H10V14H14V10ZM16 10H20V14H16V10ZM8 16H4V20H8V16ZM10 16H14V20H10V16ZM20 16H16V20H20V16Z" fill="currentColor"/>
                            </svg>
                        </button>
                        <button role="radio" :aria-selected="viewMode === 'list'" :class="{'text-gray-300 dark:text-gray-600' : viewMode !== 'list'}" @click="viewMode = 'list'">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 5H7V7H4V5ZM4 11H7V13H4V11ZM20 11H9V13H20V11ZM9 5H20V7H9V5ZM7 17H4V19H7V17ZM9 17H20V19H9V17Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="min-h-96 relative">
        <div class="container-grid">
            <div class="col-span-4 lg:col-span-6 mb-4 flex items-center gap-2" v-if="false"><!-- TODO ha feltöltőre szűrünk -->
                <span class="text-utility-button text-secondary pt-px">Feltöltő</span>
                <span class="text-body-3 lg:text-body-1">Neogrády-Kiss Barnabás</span>
            </div>
            <div class="col-span-4 lg:col-span-6 mb-6 lg:mb-10 text-body-3 lg:text-body-1 transition-opacity" :class="{ 'opacity-0': isFirstLoading }">
                <span class="text-body-1 lg:text-headline-4">{{ totalResultCount }}</span> emlék
            </div>
            <div class="absolute left-0 right-0 flex justify-center items-center pointer-events-none transition-opacity"
                 :class="{ 'top-[--section-padding]': isFirstLoading, 'bottom-0 lg:bottom-4': ! isFirstLoading, 'opacity-0': !isLoading }"
                 ref="loadingElement"></div>
            <results-thumbs v-if="! isFirstLoading && viewMode === 'large'" :results="results" :mode="'large'"></results-thumbs>
            <results-thumbs v-if="! isFirstLoading && viewMode === 'small'" :results="results" :mode="'small'"></results-thumbs>
            <results-list v-if="! isFirstLoading && viewMode === 'list'" :results="results"></results-list>
        </div>
    </section>
    <section class="larger-bottom-padding">
        <div class="container-grid transition-opacity" :class="{ 'opacity-0': isFirstLoading }">
            <div class="col-span-4 lg:col-start-2 xl:col-span-2 xl:col-start-3 flex flex-col items-center gap-4">
                <button class="btn-link px-0 lg:px-6" type="button"
                        v-if="results.length < totalResultCount"
                        @click="startSearch(true)">
                    <span>További fotó + történet megjelenítése</span>
                </button>
                <span class="text-body-4">{{ results.length }} látható a(z) {{ totalResultCount }} találatból</span>
            </div>
        </div>
    </section>
    <FiltersOffcanvas :is-open="offcanvasIsOpen" :campaigns="props.campaigns" :initial-filters="filters"
                      @close="closeFilterOffcanvas()"
                      @change-filters="changeFilters"></FiltersOffcanvas>
</template>

<script>
export default {
    name: "storiesSearch"
}
</script>

<script setup>
import Timeline from "../timeline.vue";
import {computed, nextTick, onMounted, ref, watch} from "vue";
import ResultsThumbs from "./results-thumbs.vue";
import ResultsList from "./results-list.vue";
import FiltersOffcanvas from "./filters-offcanvas.vue";
import {getAutoSuggestList} from "../helpers/auto-suggest";

const props = defineProps(['showFiltersButton', 'timelineMaxYear', 'campaigns']);
const timelineMaxYear = parseInt(props.timelineMaxYear);

const fortepanFooter = ref(null);
const stickyElement = ref(null);
const sentinel = ref(null);
const stickyStuck = ref(false);
const loadingElement = ref(null);

const filters = ref([]); // eg: { 'campaignIds': [1, 2] }
const filtersCount = computed(() => {
    let c = 0;
    for (const [key, value] of Object.entries(filters.value)) {
        c += value.length;
    }
    return c;
});

const tags = ref([]);
const uploaderId = ref(null);
const searchString = ref('');
const searchMaxYear = ref(timelineMaxYear);
const viewMode = ref('small'); // large | small | list

const isLoading = ref(true);
const isFirstLoading = ref(true);
const results = ref([]);
const currentPage = ref(1);
const totalResultCount = ref(0);
const searchInputHighlighted = computed(() => searchString.value.length > 0);
const autoSuggestionList = ref([]);
let autoSuggestionListSearchWord = '';
let autoSuggestDebounceTimer;
const autoSuggestionLoading = ref(false);

const offcanvasIsOpen = ref(false);

parseUrlParams();

watch(searchMaxYear, () => startSearch());
watch(filters, () => startSearch(), { deep: true });
watch(tags, () => startSearch(), { deep: true });
watch(searchString, () => {
    if (searchString.value) {
        document.body.classList.add('scrim-over-header-visible');
    } else {
        document.body.classList.remove('scrim-over-header-visible');
    }
});

function getSearchParams() {
    return {
        yearMax: searchMaxYear.value,
        tags: tags.value,
        page: currentPage.value,
        uploaderId: uploaderId.value,
        campaigns: filters.value.campaignIds,
    };
}

async function startSearch(loadNextPage = false) {
    if (loadNextPage) {
        currentPage.value ++;
    } else {
        currentPage.value = 1;
    }

    isLoading.value = true;
    const params = getSearchParams();
    updateUrl(params);

    const postData = {
        params: params,
    };

    const responseObj = await fetch('/jelen/story-search', {
        method: 'POST',
        headers: {
            'X-CSRF-Token' : window.csrfTokenValue,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
    });
    const response = await responseObj.json();

    isLoading.value = false;
    isFirstLoading.value = false;

    if (! response.status) {
        // TODO error handling
        return;
    }

    totalResultCount.value = response.totalCount;

    if (response.page > 1) {
        results.value = [...results.value, ...response.stories];
    } else {
        results.value = response.stories;
    }

    const oldScrollHeight = document.body.scrollHeight;
    const currentScrollPosition = window.scrollY;
    await nextTick();
    const newScrollHeight = document.body.scrollHeight;
    if (newScrollHeight !== oldScrollHeight) {
        window.scrollTo(0, currentScrollPosition);
    }
}

function updateUrl(params) {
    const searchParams = new URLSearchParams(params);
    // Page should not be part of the stateful query
    searchParams.delete('page');
    if (searchParams.get('tags') === '') {
        searchParams.delete('tags');
    }
    if (searchParams.get('yearMax') === timelineMaxYear.toString()) {
        searchParams.delete('yearMax');
    }
    if (! searchParams.get('uploaderId') || searchParams.get('uploaderId') === 'null') {
        searchParams.delete('uploaderId');
    }
    if (! searchParams.get('campaigns') || searchParams.get('campaigns') === 'undefined' || searchParams.get('campaigns') === '[]') {
        searchParams.delete('campaigns');
    }

    const searchString = searchParams.toString();
    if (location.search !== '?' + searchString) {
        const originPath = location.origin + location.pathname;
        history.pushState({}, '', originPath + (searchString ? `?${searchString}` : '') );
    }
}

function parseUrlParams() {
    if (location.search === '' || location.search === '?') {
        return;
    }
    const paramsString = location.search.substring(1);
    const searchParams = new URLSearchParams(paramsString);
    if (searchParams.has('yearMax')) {
        const yearMax = parseInt(searchParams.get('yearMax'));
        if (yearMax >= 1990 && yearMax <= timelineMaxYear) {
            searchMaxYear.value = yearMax;
        }
    }
    if (searchParams.has('tags')) {
        tags.value = searchParams.get('tags').split(',').map(tag => tag.toLowerCase());
    }
    if (searchParams.has('uploaderId')) {
        uploaderId.value = searchParams.get('uploaderId');
    }
    if (searchParams.has('campaigns')) {
        filters.value.campaignIds = searchParams.get('campaigns')
            .split(',')
            .map(id => parseInt(id))
            .filter(id => !isNaN(id));
    }
    // TODO handle all other params
}

function deleteTag(idx) {
    tags.value.splice(idx, 1);
}

function deleteAllTags() {
    tags.value = [];
}

function addTag(tag) {
    if (tags.value.indexOf(tag) === -1) {
        tags.value.push(tag);
    }
    searchString.value = '';
}

function searchInputKeydown(e) {
    if (e.key === 'Enter') {
        addTag(searchString.value);
    }
}

async function searchInputInput() {
    if (searchString.value === autoSuggestionListSearchWord || searchString.value === '') {
        return;
    }

    autoSuggestionLoading.value = true;
    clearTimeout(autoSuggestDebounceTimer);
    autoSuggestDebounceTimer = setTimeout(async () => {
        autoSuggestionListSearchWord = searchString.value;
        autoSuggestionLoading.value = true;

        try {
            const response = await getAutoSuggestList(searchString.value);

            if (!response.status) {
                // TODO error handling
                autoSuggestionLoading.value = false;
                return;
            }

            autoSuggestionLoading.value = false;
            autoSuggestionList.value = response.tags;
        } catch (error) {
            // TODO error handling for failed request
            autoSuggestionLoading.value = false;
            console.error('Failed to fetch autocomplete suggestions:', error);
        }
    }, 200);
}

function closeAutoSuggestionList() {
    searchString.value = '';
}

onMounted(() => {
    mountFortepanFooter();
    mountLoading();
    initIntersectionObserver();
    startSearch();
});

function initIntersectionObserver() {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.rootBounds) {
                stickyStuck.value = (entry.boundingClientRect.top < entry.rootBounds.top);
            }
        });
    }, {
        threshold: [0, 1],
    });
    observer.observe(sentinel.value);
}

function mountFortepanFooter() {
    if (fortepanFooter.value) {
        const templateElement = document.querySelector('template#fortepan-footer');
        if (templateElement && templateElement.content) {
            const nodeToAppend = document.importNode(templateElement.content, true).firstElementChild;
            if (nodeToAppend) {
                fortepanFooter.value.appendChild(nodeToAppend);
            }
        }
    }
}

function mountLoading() {
    if (loadingElement.value) {
        const templateElement = document.querySelector('template#offcanvas-loading-template');
        if (templateElement && templateElement.content) {
            const nodeToAppend = document.importNode(templateElement.content, true).firstElementChild;
            if (nodeToAppend) {
                loadingElement.value.appendChild(nodeToAppend);
            }
        }
    }
}

function openFiltersOffcanvas() {
    offcanvasIsOpen.value = true;
}
function closeFilterOffcanvas() {
    offcanvasIsOpen.value = false;
}
function changeFilters(newFilters) {
    filters.value = { ...filters.value, ...newFilters }
}
</script>
