import {addTrapHandler, clearTrapHandler} from "./tab-trap";
import {initStoryGallery} from "./story-gallery";

export function initOffcanvasLinks() {
    const offcanvasLinks = document.querySelectorAll('a[data-offcanvas], button[data-offcanvas]');
    for (const offcanvasLink of offcanvasLinks) {
        offcanvasLink.addEventListener('click', e => {
            e.preventDefault();
            openOffcanvasLink(offcanvasLink);
        });
    }
}

export async function openOffcanvasLink(link) {
    const scrim = document.getElementById('scrim-over-header');
    const offcanvas = document.getElementById('offcanvas');
    if (offcanvas.classList.contains('show') || offcanvas.classList.contains('showing')) {
        return;
    }
    document.body.classList.add('scrim-over-header-visible', 'overflow-hidden');
    scrim.addEventListener('click', closeOffcanvas);
    setTimeout(() => {
        offcanvas.classList.add('showing');
        setTimeout(() => {
            offcanvas.classList.remove('showing');
            offcanvas.classList.add('show');
        }, 300);
    });

    const loadingTemplate = document.getElementById('offcanvas-loading-template');
    if (loadingTemplate) {
        offcanvas.innerHTML = loadingTemplate.innerHTML;
    }

    if (link.dataset.url) {
        offcanvas.innerHTML = await (await fetch(link.dataset.url)).text();
    } else if (link.dataset.myStoryId) {
        const url = '/actions/jelen/my-stories/get-offcanvas-content?id=' + link.dataset.myStoryId;
        offcanvas.innerHTML = await (await fetch(url)).text();
        initStoryGallery();
    }

    hookOffcanvasEventListeners(offcanvas);
    addTrapHandler(offcanvas);
}

function hookOffcanvasEventListeners(offcanvas) {
    const offcanvasCloseButton = offcanvas.querySelector('.close');
    if (offcanvas && offcanvasCloseButton) {
        offcanvasCloseButton.addEventListener('click', e => {
            e.preventDefault();
            closeOffcanvas();
        });
    }
    const offcanvasTitle = offcanvas.querySelector('#offcanvas-title')
    if (offcanvas && offcanvasTitle) {
        offcanvas.addEventListener('scroll', () => {
            offcanvasTitle.classList.toggle('lg:text-headline-4', offcanvas.scrollTop <= 0);
            offcanvasTitle.classList.toggle('lg:text-body-1', offcanvas.scrollTop > 0);
        });
    }
}

function closeOffcanvas() {
    const scrim = document.getElementById('scrim-over-header');
    const offcanvas = document.getElementById('offcanvas');
    if (!offcanvas.classList.contains('show')) {
        return;
    }
    document.body.classList.remove('scrim-over-header-visible', 'overflow-hidden');
    scrim.removeEventListener('click', closeOffcanvas);
    setTimeout(() => {
        offcanvas.classList.add('hiding');
        setTimeout(() => {
            offcanvas.classList.remove('show', 'hiding');
        }, 300);
    });
    clearTrapHandler(offcanvas);
}
