import { computePosition, offset, arrow } from "@floating-ui/dom";
import {setTabindices} from "./tab-trap";

export function initTooltips() {
    const tooltipOpeners = document.querySelectorAll('.has-tooltip[data-target]');
    for (const tooltipOpener of tooltipOpeners) {
        const tooltipElement = document.getElementById(tooltipOpener.dataset.target);
        if (tooltipElement) {

            const arrowElement = tooltipElement.querySelector('.arrow');
            const offsetY = isNaN(parseInt(tooltipOpener.dataset.offseyY)) ? 0 : parseInt(tooltipOpener.dataset.offseyY);
            computePosition(tooltipOpener, tooltipElement, {
                placement: tooltipOpener.dataset.placement || 'bottom',
                middleware: arrowElement ? [offset(10), arrow({ element: arrowElement })] : null,
            }).then(({x, y, middlewareData}) => {
                Object.assign(tooltipElement.style, {
                    top: `${y+offsetY}px`,
                    left: `${x}px`,
                });
                if (middlewareData.arrow) {
                    const { x } = middlewareData.arrow;
                    Object.assign(arrowElement.style, {
                        left: `${x}px`,
                        top: `${-arrowElement.offsetHeight / 2}px`
                    });
                }
            });

            setTabindices(tooltipElement, -1);
            if (tooltipOpener.classList.contains('click')) {
                tooltipOpener.addEventListener('click', e => tooltipEvent(e, tooltipElement));
            } else if (tooltipOpener.classList.contains('hover')) {
                tooltipOpener.addEventListener('mouseenter', e => tooltipEvent(e, tooltipElement, true));
                tooltipOpener.addEventListener('mouseleave', e => tooltipEvent(e, tooltipElement, false));
                tooltipOpener.addEventListener('focus', e => tooltipEvent(e, tooltipElement, true));
                tooltipOpener.addEventListener('blur', e => tooltipEvent(e, tooltipElement, false));
            }

        }
    }
}

function tooltipEvent (e, tooltipElement, forceState) {
    e.preventDefault();
    tooltipElement.classList.toggle('show', forceState);
    setTabindices(tooltipElement, tooltipElement.classList.contains('show') ? 0 : -1);
}
