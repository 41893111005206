const ACCEPTED_KEY = 'ja_adult_accepted';
const SHOWN_KEY = 'ja_adult_popup_shown';

let adultPopup = null;
let acceptCallback = null;
let denyCallback = null;

export function initAdultPopup() {
    adultPopup = document.getElementById('footer-adult-popup');
    if (! adultPopup) {
        return;
    }
    adultPopup.querySelector('.adult-popup-close').addEventListener('click', () => {
        closeAdultPopup();
        if (denyCallback && typeof denyCallback === 'function') {
            denyCallback();
        }
    });
    adultPopup.querySelector('.adult-cancel').addEventListener('click', () => {
        closeAdultPopup();
        if (denyCallback && typeof denyCallback === 'function') {
            denyCallback();
        }
    });
    adultPopup.querySelector('.adult-accept').addEventListener('click', () => {
        localStorage.setItem(ACCEPTED_KEY, '1');
        closeAdultPopup();
        if (acceptCallback && typeof acceptCallback === 'function') {
            acceptCallback();
        }
    });
}

export function isAdultPopupAccepted() {
    return localStorage.getItem(ACCEPTED_KEY) === '1';
}

export function isAdultPopupDeclined() {
    return isAdultPopupPresented() && ! isAdultPopupAccepted();
}

export function isAdultPopupPresented() {
    return localStorage.getItem(SHOWN_KEY) === '1';
}

export function showAdultPopup(_acceptCallback = null, _denyCallback = null) {
    acceptCallback = _acceptCallback;
    denyCallback = _denyCallback;
    if (! adultPopup) {
        return;
    }
    document.body.classList.add('scrim-over-header-visible');
    adultPopup.classList.remove('translate-y-full', 'md:pointer-events-none', 'md:opacity-0');
}

export function closeAdultPopup() {
    if (! adultPopup) {
        return;
    }
    document.body.classList.remove('scrim-over-header-visible');
    adultPopup.classList.add('translate-y-full', 'md:pointer-events-none', 'md:opacity-0');
}
