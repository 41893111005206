import Swiper from 'swiper';
import {EffectFade, Keyboard, Navigation} from "swiper/modules";
import {addTrapHandler, clearTrapHandler} from "./tab-trap";

export function initStoryGallery() {
    const galleryWrapper = document.querySelector('.story-gallery');
    if (!galleryWrapper) {
        return;
    }

    const swiperParams = {
        effect: "fade",
        fadeEffect: {crossFade: true},
        modules: [EffectFade],
        loop: true,
    };
    const swiper = new Swiper(galleryWrapper.querySelector('.swiper'), swiperParams);

    const thumbnails = galleryWrapper.querySelectorAll('.story-gallery-thumbnails a');
    for (const thumbnail of thumbnails) {
        thumbnail.addEventListener('click', e => {
            e.preventDefault();
            const idx = [...thumbnail.parentNode.children].indexOf(thumbnail);
            swiper.slideTo(idx);
        });
    }
}

export function initStoryLightboxGallery() {
    const galleryOpenLinks = document.querySelectorAll('.story-gallery .open-lightbox-gallery');
    for (const galleryOpenLink of galleryOpenLinks) {
        galleryOpenLink.addEventListener('click', e => {
            e.preventDefault();
            openLightboxGallery();
        });
    }
    const storyLightboxCloseButton = document.querySelector('.story-lightbox-gallery .close');
    if (storyLightboxCloseButton) {
        storyLightboxCloseButton.addEventListener('click', e => {
            e.preventDefault();
            closeLightboxGallery();
        });
    }
}

function openLightboxGallery() {
    const storyLightbox = document.querySelector('.story-lightbox-gallery');
    if (!storyLightbox) {
        return;
    }
    document.body.classList.add('overflow-hidden');
    storyLightbox.classList.remove('hidden');
    setTimeout(() => {
        storyLightbox.classList.remove('opacity-0');
        storyLightbox.classList.add('opacity-100');
        if (!storyLightbox.dataset.inited) {
            storyLightbox.dataset.inited = '1';
            initStoryLightboxSwiper(storyLightbox);
        }
    });
}

function initStoryLightboxSwiper(storyLightbox) {
    const prevButton = storyLightbox.querySelector('.swiper-prev');
    const nextButton = storyLightbox.querySelector('.swiper-next');
    const statusElem = storyLightbox.querySelector('.swiper-status');
    const slides = storyLightbox.querySelectorAll('.swiper-slide');
    if (slides.length > 1) {
        const swiperParams = {
            centeredSlides: true,
            spaceBetween: 24,
            breakpoints: {
                600: {
                    spaceBetween: 96,
                    slidesPerView: 1.5,
                },
                1024: {
                    spaceBetween: 200,
                },
                1280: {
                    slidesPerView: 1.75,
                }
            },
            keyboard: {
                enabled: true,
            },
            modules: [Navigation, Keyboard],
            navigation: {
                disabledClass: 'opacity-40',
                prevEl: prevButton,
                nextEl: nextButton,
            },
            on: {
                afterInit: (swiper) => {
                    swiper.slides.forEach((slide, idx) => {
                        slide.querySelector('img').classList.add(idx ? 'opacity-20' : 'opacity-100');
                    });
                    updateStatus(swiper, statusElem);
                },
                slideChange: (swiper) => {
                    for (let i = 0; i < swiper.slides.length; i++) {
                        const img = swiper.slides[i].querySelector('img');
                        img.classList.toggle('opacity-20', i !== swiper.activeIndex);
                        img.classList.toggle('opacity-100', i === swiper.activeIndex);
                    }
                    updateStatus(swiper, statusElem);
                },
            }
        };
        new Swiper(storyLightbox.querySelector('.swiper'), swiperParams);
    } else {
        prevButton.classList.add('hidden');
        nextButton.classList.add('hidden');
    }
    addTrapHandler(storyLightbox);
}

function updateStatus(swiper, statusElem) {
    const statusIdx = (swiper.activeIndex + 1).toLocaleString('hu-HU', { minimumIntegerDigits: 2 });
    const statusAll = (swiper.slides.length).toLocaleString('hu-HU', { minimumIntegerDigits: 2 });
    statusElem.innerText = `${statusIdx}/${statusAll}`;
}

function closeLightboxGallery() {
    const storyLightbox = document.querySelector('.story-lightbox-gallery');
    if (!storyLightbox) {
        return;
    }
    storyLightbox.classList.remove('opacity-100');
    storyLightbox.classList.add('opacity-0');
    setTimeout(() => {
        storyLightbox.classList.add('hidden');
        document.body.classList.remove('overflow-hidden');
    }, 300);
    clearTrapHandler(storyLightbox);
}
