export function initMyStoriesInfiniteScroll() {
    const sentryElem = document.getElementById('my-stories-pagination-sentry');
    if (! sentryElem) {
        return;
    }
    const loadingElement = document.querySelector('.my-stories-pagination-loading');
    if (loadingElement) {
        const templateElement = document.querySelector('template#offcanvas-loading-template');
        if (templateElement && templateElement.content) {
            const nodeToAppend = document.importNode(templateElement.content, true).firstElementChild;
            if (nodeToAppend) {
                loadingElement.appendChild(nodeToAppend);
            }
        }
    }

    let canLoad = true;
    const options = { rootMargin: '0px', threshold: 1 };
    const observer = new IntersectionObserver(async ([entry]) => {
        if (entry.isIntersecting && canLoad && loadingElement.classList.contains('hidden')) {
            loadingElement.classList.remove('hidden');
            const existingElementCount = document.querySelectorAll('.my-story-entry').length;
            const type = sentryElem.dataset.type;
            const response = await (await fetch(`/actions/jelen/my-stories/get-my-stories?offset=${existingElementCount}&type=${type}`)).json();
            loadingElement.classList.add('hidden');
            if (response.status && response.count > 0) {
                sentryElem.insertAdjacentHTML('beforebegin', response.html);
            } else {
                canLoad = false;
            }
        }
    }, options);
    observer.observe(sentryElem);
}
