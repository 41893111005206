import {createApp} from "vue";
import StoriesSearch from "../vue/stories-search/stories-search.vue";

export function initStoriesSearch() {
    const appRoot = document.getElementById('stories-search');
    if (! appRoot) {
        return;
    }
    const showFiltersButton = appRoot.dataset.showFiltersButton === 'true';
    const timelineMaxYear = appRoot.dataset.maxYear;
    const campaigns = JSON.parse(appRoot.dataset.campaigns || '[]');
    createApp(StoriesSearch, { showFiltersButton, timelineMaxYear, campaigns }).mount(appRoot);

    // Trick: move app template outside the mount point
    for (const childElement of [...appRoot.children]) {
        appRoot.insertAdjacentElement('beforebegin', childElement);
    }
    appRoot.classList.add('hidden');
}
