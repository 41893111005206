export function initTimelineBlock() {
    const timelineWrappers = document.querySelectorAll('section.timeline-photos .timeline-wrapper');
    for (const timelineWrapper of timelineWrappers) {
        const appContainer = timelineWrapper.querySelector('.timeline-app');
        if (appContainer) {
            appContainer.addEventListener('timelineChange', (e) => {
                const year = e.detail.value;
                window.location.href = '/tortenetek?yearMax=' + year;
            });
        }
    }
}
