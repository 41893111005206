let lastTrapEventHandler = null;

export function addTrapHandler(modal) {
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const firstFocusableElement = modal.querySelector(focusableElements);
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    if (!modal.dataset.trapInit) {
        modal.dataset.trapInit = '1';
        lastTrapEventHandler = handleTrapEvent(lastFocusableElement, firstFocusableElement);
        document.addEventListener('keydown', lastTrapEventHandler);
    }
}

export function clearTrapHandler(modal) {
    if (lastTrapEventHandler) {
        modal.removeAttribute('data-trap-init');
        document.removeEventListener('keydown', lastTrapEventHandler);
    }
}

export function setTabindices(wrapper, value) {
    const elements = wrapper.querySelectorAll('input, button, a, textarea, select');
    for (const element of elements) {
        if (value) {
            element.setAttribute('tabindex', value);
        } else {
            element.removeAttribute('tabindex');
        }
    }
}

function handleTrapEvent(lastFocusableElement, firstFocusableElement) {
    return function eventHandler(event) {
        const isTabPressed = event.key === 'Tab' || event.keyCode === 9;

        if (!isTabPressed) {
            return;
        }

        if (event.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
                event.preventDefault();
            }
        } else if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus();
            event.preventDefault();
        }
    };
}
