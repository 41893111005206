import {createApp} from "vue";
import storyUploadStep3 from "../vue/story-upload/story-upload-step-3.vue";
import {EffectFade, Keyboard, Navigation} from "swiper/modules";
import Swiper from "swiper";

export function initStoryUploadStep3() {
    const appRoot = document.getElementById('story-upload-step-3-app');
    if (appRoot) {
        createApp(storyUploadStep3, { appRoot }).mount(appRoot);
    }
    const miniGalleryWrappers = document.querySelectorAll('.story-upload-minigallery');
    for (const miniGalleryWrapper of miniGalleryWrappers) {
        initStoryUploadGallery(miniGalleryWrapper);
    }
}

function initStoryUploadGallery(miniGalleryWrapper) {
    const prevButton = miniGalleryWrapper.querySelector('.swiper-prev');
    const nextButton = miniGalleryWrapper.querySelector('.swiper-next');
    const swiperParams = {
        effect: "fade",
        fadeEffect: {crossFade: true},
        modules: [EffectFade, Navigation, Keyboard],
        loop: false,
        navigation: {
            disabledClass: 'opacity-40',
            prevEl: prevButton,
            nextEl: nextButton,
        },
    };
    const swiper = new Swiper(miniGalleryWrapper.querySelector('.swiper'), swiperParams);
}
